import React from 'react';
import { Filter, Layout, ViewTable } from '@/components';
import { IFilterField } from '@/components/filter/Filter';
import LibEnum from 'lib/enum';
import WebApi, { YchApi } from '@/web/api';
import { observer } from 'mobx-react';
import globalUserStore from '@/global_store/globalUserStore';

@observer
export default class Log extends React.PureComponent {
  private table = React.createRef<ViewTable>();
  private queryParams: {
    targetName: string;
    eventType: string;
    datetimeRange?: [string, string]
  } = {
    targetName: '',
    eventType: '',
    datetimeRange: undefined,
  }

  render() {
    const fields: IFilterField[] = [
      {
        type: 'select',
        name: '类型',
        field: 'eventType',
        options: [
          {
            name: '登录',
            value: LibEnum.LOG_EVENT_TYPE.LOGIN.value,
          },
          {
            name: '查看',
            value: LibEnum.LOG_EVENT_TYPE.FIND.value,
          },
          {
            name: '新增',
            value: LibEnum.LOG_EVENT_TYPE.CREATE.value,
          },
          {
            name: '修改',
            value: LibEnum.LOG_EVENT_TYPE.UPDATE.value,
          },
          {
            name: '删除',
            value: LibEnum.LOG_EVENT_TYPE.DELETE.value,
          }
        ]
      },
      {
        type: 'input',
        field: 'targetName',
        name: '搜索',
        placeholder: '员工姓名'
      },
      {
        type: 'dateRange',
        field: 'datetimeRange',
        name: '时间',
      }
    ]

    if (globalUserStore.isYch()) {
      fields.unshift({
        type: 'select',
        field: 'agentId',
        name: '代理商',
        width: 200,
        options: async () => {
          const data: any = await YchApi.company_list();
          return data.map(item => {
            return {
              name: item.companyName,
              value: item.companyId,
            };
          });
        }
      });
    }
    return <Layout.Page>
      <Filter
        fields={fields}
        onChange={values => {
          this.queryParams = values as any;
          this.table.current?.reset(this.queryParams);
        }}
      />
      <ViewTable
        ref={this.table}
        flex1
        columns={[
          ...(globalUserStore.isYch() ? [{
            title: '代理商',
            width: 200,
            dataIndex: 'agentName'
          }] : []),
          {
            title: '操作时间',
            width: 200,
            align: 'center',
            dataIndex: 'createdAt'
          },
          {
            title: 'IP',
            width: 200,
            align: 'center',
            dataIndex: 'ip',
            render: v => v || '--'
          },
          {
            title: '操作类型',
            width: 100,
            align: 'center',
            dataIndex: 'eventTypeDesc',
          },
          {
            title: '操作人',
            width: 150,
            dataIndex: 'targetName',
          },
          {
            title: '操作内容',
            width: 'auto',
            dataIndex: 'content'
          },
        ]}
        fetch={(data) => {
          if (globalUserStore.isYch()) {
            return YchApi.action_log_paging(data);
          }
          return WebApi.action_log_paging(data);
        }}
        rowKey={r => r.logId}
        />
    </Layout.Page>
  }
}